import styled, { css } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";
import { ReactSVG } from "react-svg";

import IconArrowRight from "@assets/svg/arrow-right.svg";
import Link from "@components/atoms/Link";

import { CardWrapperProps } from "./types";

export const Card = styled(FlexBox)`
  padding: 2.5rem 1.25rem;
  height: 100%;
  width: 100%;

  @media ${media.mobile} {
    padding: 3.25rem 2rem;
  }

  @media ${media.tablet} {
    padding: 2.25rem;
  }
`;

export const IconCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4rem;
  padding: 1.5rem;
  min-height: 8rem;
  min-width: 8rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.15);
  transform: translateY(50%);

  & + ${Card} {
    padding-top: 7.625rem;
    padding-bottom: 4rem;
  }

  @media ${media.mobile} {
    & + ${Card} {
      padding-top: 6.125rem;
    }
  }
`;

export const Wrapper = styled(FlexBox).withConfig({
  shouldForwardProp
})<CardWrapperProps>`
  position: relative;

  ${({ backgroundColor }) => css`
    ${Card} {
      background-color: ${backgroundColor};
    }

    ${IconCard} {
      background-color: ${backgroundColor};
    }
  `}
`;

export const Icon = styled(ReactSVG)`
  margin-bottom: 1.5rem;

  svg {
    fill: currentColor;
  }

  @media ${media.mobile} {
    margin-bottom: 3.125rem;
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  padding-top: 3rem;
`;

export const ArrowRightIcon = styled(IconArrowRight)`
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
  fill: currentColor;
  transition: ${({ theme }) => theme.transition};
  transition-property: transform;
`;

export const CardLink = styled(Link)`
  position: absolute;
  inset: 0;
  cursor: pointer;
  z-index: 1;

  @media ${media.tablet} {
    &:hover ~ ${Card} ${ArrowRightIcon} {
      transform: translateX(0.625rem);
    }
  }
`;
