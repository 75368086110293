import React from "react";

import CardWithIcon from "./partials/CardWithIcon";
import { CardsWithIconProps } from "./types";
import * as Styled from "./styles";

const CardsWithIcon: React.FC<CardsWithIconProps> = ({ items }) =>
  items.length ? (
    <Styled.List
      as="ul"
      gridGap={["4rem", "4rem", "1.875rem"]}
      gridTemplateColumns={["", "", "repeat(2, 1fr)"]}
      aria-label="Cards with icon"
    >
      {items.map(
        ({
          key,
          icon,
          contentIcon,
          content,
          link,
          backgroundColor
        }) => (
          <li {...{ key }}>
            <CardWithIcon
              {...{
                icon,
                contentIcon,
                content,
                link,
                backgroundColor
              }}
            />
          </li>
        )
      )}
    </Styled.List>
  ) : null;

export default CardsWithIcon;
