import React from "react";
import { storyblokEditable } from "@storyblok/react";
import { useTheme } from "styled-components";

import LazyHydrate from "@components/utils/LazyHydrate";
import RichText from "@components/atoms/RichText";
import CardsWithIcon from "@components/molecules/CardsWithIcon";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";
import { CardsWithIconItemProps } from "@components/molecules/CardsWithIcon/types";

import { CardsWithIconStoryblokProps } from "./types";

const CardsWithIconStoryblok: React.FC<
  CardsWithIconStoryblokProps
> = ({ blok }) => {
  const { items, cardBackgroundColor } = blok;
  const theme = useTheme();

  const targetCardsBackgroundColor =
    cardBackgroundColor || theme.colors.white;

  const cards: CardsWithIconItemProps[] = items?.map(
    ({
      _uid,
      icon,
      contentIcon,
      content,
      link: {
        url,
        cached_url: cachedUrl,
        rel,
        target,
        story: { full_slug: fullSlug = "" } = {}
      } = {}
    }) => {
      const {
        text,
        mobileTextAlignment,
        tabletTextAlignment,
        desktopTextAlignment,
        underlineType
      } = content[0] ?? {};
      const newestUrl = fullSlug || cachedUrl;

      return {
        key: _uid,
        contentIcon:
          contentIcon && tryToReplaceDomain(contentIcon.filename),
        icon: icon && tryToReplaceDomain(icon.filename),
        backgroundColor: targetCardsBackgroundColor,
        content: (
          <RichText
            content={text}
            {...{
              mobileTextAlignment,
              tabletTextAlignment,
              desktopTextAlignment,
              underlineType
            }}
          />
        ),
        link: {
          href: url || newestUrl,
          rel,
          target
        }
      };
    }
  );

  return cards.length ? (
    <LazyHydrate
      useDisplayContents={false}
      whenVisible
      getEventTarget={() => window}
    >
      <CardsWithIcon items={cards} {...storyblokEditable(blok)} />
    </LazyHydrate>
  ) : null;
};

export default CardsWithIconStoryblok;
