import React from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { ReactSVG } from "react-svg";

import LazyHydrate from "@components/utils/LazyHydrate";

import { CardWithIconProps } from "./types";
import * as Styled from "./styles";

const CardWithIcon: React.FC<CardWithIconProps> = ({
  icon,
  content,
  contentIcon,
  link: { href, rel, target } = {},
  backgroundColor
}) => (
  <Styled.Wrapper
    flexDirection="column"
    alignItems="center"
    height="100%"
    {...{ backgroundColor }}
  >
    {href && (
      <Styled.CardLink
        {...{ href, rel, target }}
        aria-label="Read more link"
      />
    )}
    {icon && (
      <Styled.IconCard>
        <LazyHydrate whenVisible>
          <ReactSVG src={icon} />
        </LazyHydrate>
      </Styled.IconCard>
    )}
    <Styled.Card flexDirection="column">
      {contentIcon && (
        <LazyHydrate whenVisible>
          <Styled.Icon src={contentIcon} />
        </LazyHydrate>
      )}
      {typeof content === "string" ? (
        <Typography variant="body8">{content}</Typography>
      ) : (
        content
      )}
      {href && (
        <Styled.Footer>
          <Styled.ArrowRightIcon />
        </Styled.Footer>
      )}
    </Styled.Card>
  </Styled.Wrapper>
);

export default CardWithIcon;
