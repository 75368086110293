import styled from "styled-components";
import { GridBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

export const List = styled(GridBox)`
  @media ${media.mobile} {
    margin: 0 4rem;
  }

  @media ${media.tablet} {
    margin: 0;
  }
`;
